var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-simple-table',[_c('thead',[_c('tr',[_c('th',[_vm._v("Image")]),_c('th',[_vm._v("Title")]),_c('th',[_vm._v("Notes")]),_c('th',[_vm._v("Serving Quality")]),_c('th',[_vm._v("Serving UOM")])])]),_c('tbody',{directives:[{name:"infinite-scroll",rawName:"v-infinite-scroll",value:(_vm.loadMore),expression:"loadMore"}],attrs:{"infinite-scroll-disabled":_vm.disableListScroll}},[_vm._l((_vm.list),function(food){return _c('tr',{key:food.id,staticClass:"clickable new-tab-link-row"},[_c('td',{staticClass:"pa-2"},[_c('cloudinary-image',{attrs:{"image":food.cloudinary_image,"tile":true,"alt":"Food Recipe","height":"70","size":"70","contain":""}}),_c('router-link',{staticClass:"row-link",attrs:{"to":{
              name: 'form.food',
              params: { id: food.id },
            }}})],1),_c('td',[_vm._v(" "+_vm._s(food.title)+" "),_c('router-link',{staticClass:"row-link",attrs:{"to":{
              name: 'form.food',
              params: { id: food.id },
            }}})],1),_c('td',{attrs:{"width":"60%"}},[_vm._v(" "+_vm._s(food.description)+" "),_c('router-link',{staticClass:"row-link",attrs:{"to":{
              name: 'form.food',
              params: { id: food.id },
            }}})],1),_c('td',[_vm._v(" "+_vm._s(food.serving_quantity)+" "),_c('router-link',{staticClass:"row-link",attrs:{"to":{
              name: 'form.food',
              params: { id: food.id },
            }}})],1),_c('td',[_vm._v(" "+_vm._s(food.serving_unit)+" "),_c('router-link',{staticClass:"row-link",attrs:{"to":{
              name: 'form.food',
              params: { id: food.id },
            }}})],1)])}),(_vm.loading)?_c('table-skeleton-loader',{attrs:{"column":"5","row":""}}):_vm._e()],2)]),(!_vm.list.length && !_vm.loading)?_c('no-list',{attrs:{"details":"No Available Foods"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }