<template>
  <div>
    <v-simple-table>
      <thead>
        <tr>
          <th>Image</th>
          <th>Title</th>
          <th>Notes</th>
          <th>Serving Quality</th>
          <th>Serving UOM</th>
        </tr>
      </thead>
      <tbody
        v-infinite-scroll="loadMore"
        :infinite-scroll-disabled="disableListScroll"
      >
        <tr
          v-for="food in list"
          :key="food.id"
          class="clickable new-tab-link-row"
        >
          <td class="pa-2">
            <cloudinary-image
              :image="food.cloudinary_image"
              :tile="true"
              alt="Food Recipe"
              height="70"
              size="70"
              contain
            />

            <router-link
              :to="{
                name: 'form.food',
                params: { id: food.id },
              }"
              class="row-link"
            />
          </td>
          <td>
            {{ food.title }}
            <router-link
              :to="{
                name: 'form.food',
                params: { id: food.id },
              }"
              class="row-link"
            />
          </td>
          <td width="60%">
            {{ food.description }}
            <router-link
              :to="{
                name: 'form.food',
                params: { id: food.id },
              }"
              class="row-link"
            />
          </td>
          <td>
            {{ food.serving_quantity }}
            <router-link
              :to="{
                name: 'form.food',
                params: { id: food.id },
              }"
              class="row-link"
            />
          </td>
          <td>
            {{ food.serving_unit }}
            <router-link
              :to="{
                name: 'form.food',
                params: { id: food.id },
              }"
              class="row-link"
            />
          </td>
        </tr>

        <table-skeleton-loader v-if="loading" column="5" row />
      </tbody>
    </v-simple-table>

    <no-list v-if="!list.length && !loading" details="No Available Foods" />
  </div>
</template>

<script>
import TableSkeletonLoader from '@/components/elements/loaders/TableSkeleton'
import CloudinaryImage from '@/components/elements/CloudinaryImage'
import NoList from '@/components/elements/NoList'
import infiniteScroll from 'vue-infinite-scroll'

import { mdiDotsVertical } from '@mdi/js'

export default {
  name: 'FoodListTable',

  components: {
    TableSkeletonLoader,
    CloudinaryImage,
    NoList,
  },

  directives: {
    infiniteScroll,
  },

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    list: {
      type: Array,
      default: () => [],
      required: true,
    },
    meta: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      icons: {
        action: mdiDotsVertical,
      },
    }
  },

  computed: {
    disableListScroll() {
      return this.loading || this.meta.current_page >= this.meta.last_page
    },
  },

  methods: {
    loadMore() {
      this.$emit('loadMore', this.meta.current_page + 1)
    },

    showDetails(food) {
      this.$emit('goTo', food.id)
    },
  },
}
</script>
